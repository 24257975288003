import { $All } from "Packages/Bahn/DB.Neos.Base/Resources/Private/Assets/Scripts/Modules/Helper";

//
// Accordion
//

var hash = window.location.hash;

var accordion = $All(".js-accordion");
var accordionButton = $All(".js-accordion__button");
//var accordionCollapse = $All(".js-accordion__collapse");

// check if hash has changed
function locationHashChanged() {
  const el = document.getElementById(location.hash.substring(1));
  if (el && el.classList.contains("js-accordion__button")) {
    toggleAccordionItem(el);
  }
}

function toggleAccordionItem(el) {
  var isopen = el.getAttribute("aria-expanded");
  var parent = el.parentNode.parentNode;
  var title = parent.querySelector(".accordion__title");
  var content = parent.querySelector(".accordion__collapse");

  if (isopen == "false") {
    el.setAttribute("aria-expanded", true);
    parent.classList.add("is--open");
    parent.classList.remove("accordion--no-overflow");
    parent.classList.add("accordion--overflow");
    title.classList.add("is--open");
    el.blur();

    content.style.height = "auto";
    var height = content.clientHeight + "px";
    content.style.height = "0px";

    setTimeout(function () {
      content.style.height = height;
    }, 0);
  } else {
    content.style.height = "0px";
    parent.classList.remove("accordion--overflow");
    parent.classList.add("accordion--no-overflow");

    content.addEventListener(
      "transitionend",
      function () {
        el.setAttribute("aria-expanded", false);
        el.blur();
        parent.classList.remove("is--open");
        title.classList.remove("is--open");
      },
      {
        once: true,
      }
    );
  }
}

if (accordion.length > 0) {
  accordionButton.forEach(function (el) {
    el.addEventListener("click", function (e) {
      e.preventDefault();
      toggleAccordionItem(el);
    });
  });

  window.addEventListener("hashchange", locationHashChanged);

  // check if uri has hash
  if (hash != "") {
    const el = document.getElementById(hash.substring(1));
    if (el && el.classList.contains("js-accordion__button")) {
      toggleAccordionItem(el);
    }
  }
}
